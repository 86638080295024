import React from 'react';
import Search from '../Search';
import IUserList from '../../utils/UserList/UserList.interface';
import { CreateUserForm } from './CreateUser';
import { IPPHeading, IPPParagraph } from '../../library/Heading/Heading';
import UserRow from './UserRow';
import ConditionalLoader from '../Hoc/ConditionalLoader';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 * @createUser - Dummy function to create user.
 * @valuesToShoe - Store the @numValuesToShow data.
 **/
interface UserListProp {
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  users: IUserList[];
  email: string;
  partnerName: string;
  onDeleteUser: (email: string) => void;
  onEditUser: (user: IUserList) => void;
  onUserCreated?: () => void;
}
// React functional component that displays a list of Admin users in a table format with a search bar and a dropdown to change the number of users to display.
const UserList: React.FC<UserListProp> = ({
  isLoading,
  query,
  setQuery,
  users,
  email,
  partnerName,
  onUserCreated,
  onEditUser,
  onDeleteUser
}) => {
  const orgEmail = sessionStorage.getItem('userEmail');
  const partnerCompanyName = sessionStorage.getItem('companyName');
  const partnerDomainList = sessionStorage.getItem('domainList');
  const orgDomain = partnerDomainList != null ? JSON.parse(partnerDomainList) : [];

  return (
    <div className="w-full">
      <div className="bg-white block w-full" style={{ paddingInline: '2px 2px' }}>
        <IPPHeading
          headerText={'Organisation Users'}
          className={'w-fit text-black text-xl border-b-2 pl-6 pr-2 pb-2 mb-4'}
        />
        <div className="w-full flex flex-wrap-reverse justify-between gap-2">
          <div className="w-fit">
            <Search
              onChange={(e: any) => setQuery(e.target.value)}
              text="Search by name..."
              hideLabel
            />
          </div>
          <CreateUserForm
            organisationEmail={orgEmail}
            organisationName={partnerCompanyName}
            organisationDomain={orgDomain}
            callback={onUserCreated}
          />
        </div>
        {/* Todo - fix with next release */}
        {/* <Show value={numValuesToShow} onChange={handleNumValuesChange} /> */}
        <ConditionalLoader isLoading={isLoading}>
          <div className="w-full overflow-y-auto pt-2">
            {/* If data is available, display the table */}
            <div className="min-w-fit overflow-hidden rounded-lg border">
              <table className="min-w-fit w-full whitespace-nowrap bg-white divide-y divide-gray-300">
                <thead className="bg-[#f8f8f8]">
                  <tr className="bg-offwhite text-black text-center">
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Display Name{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Email{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Role{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Action{' '}
                    </th>
                  </tr>
                </thead>
                <tbody className="min-w-fit divide-y divide-gray-200">
                  <tr className="text-light-gray min-w-fit">
                    <td className="px-6 py-4 text-center">
                      {' '}
                      <IPPParagraph headerText={`${partnerName}`} className={''} />
                    </td>
                    {email === null ? (
                      <td className="px-6 py-4 text-center hidden">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    ) : (
                      <td className="px-6 py-4 text-center ">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    )}
                    <td className="px-6 py-4 text-center ">
                      {' '}
                      <IPPParagraph headerText={'Admin'} className={''} />
                    </td>
                  </tr>
                  {/* data is mapped into db and the values are stored of type IUserList. Here the index is of type number which generally determines the key value */}
                  {users
                    .sort((a, b) => a.name!.localeCompare(b.name!))
                    .filter((user) => user.name!.toLowerCase().includes(query.toLowerCase()))
                    .map((user: IUserList, index: number) => (
                      <UserRow
                        key={index}
                        user={user}
                        onDeleteUser={onDeleteUser}
                        onEditUser={onEditUser}
                        disabled={false}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </ConditionalLoader>
      </div>
    </div>
  );
};

export default UserList;
