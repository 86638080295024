import React, { Fragment, useContext, useEffect, useState } from 'react';
import { LoginContext } from '../context/IppContext';
import { FederatedIdentity } from '../utils/AccessCredentials/FederatedIdentity';
import { downloadFile } from '../utils/Helper/downloadFiles';
import Downloadfile from '../library/DownloadFiles/DownloadFile';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import OrgUser from '../utils/OrgUser/OrgUser';
import ModuleGrid from '../components/UserPanel/ModuleGrid';
import AwsLogin from '../utils/AccessCredentials/AwsLogin';
import { AWS_SIGNIN_URL } from '../utils/Helper/helper';
import Button from '../library/Button/button';
import newTabIcon from '../assets/img/newTabIcon.jpg';
import { IPPFourthLevelHeading, IPPHeading, IPPParagraph } from '../library/Heading/Heading';
import ConditionalLoader from '../components/Hoc/ConditionalLoader';
import CopyIcon from '@rsuite/icons/Copy';

const UserPanel: React.FC<any> = () => {
  const { loginToken } = useContext(LoginContext);
  const [accessKey, setAccessKey] = useState<String>('');
  const [secret, setSecret] = useState<String>('');
  const [session, setSession] = useState<String>('');
  const [roleARN, setRoleArn] = useState<String>('');
  const [error, setError] = useState<string>(''); // New error state
  const [copiedCred, setCopiedCred] = useState<boolean>(false);
  const parsedLoginToken = JSON.parse(loginToken);
  const userPoolId = parsedLoginToken.userPoolId;
  const identityPoolId = parsedLoginToken.IdentityPoolId;
  const federatedIdentity = new FederatedIdentity({
    userPoolId,
    identityPoolId
  });
  const [moduleList, setModuleList]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFederatedSessionLoading, setIsFederatedSessionLoading] = useState<boolean>(false);
  const [openingCodeCommit, setOpeningCodeCommit] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [isShowCred, setIsShowCred] = useState<boolean>(false);
  // To handle show functionality
  const [numValuesToShow, setNumValuesToShow] = useState('');
  // To handle change based on event on show bar.
  const handleNumValuesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setNumValuesToShow(value);
  };
  const navigate: NavigateFunction = useNavigate();
  //   Storing the user's name and displaying it as a welcome page
  const userEmail = sessionStorage.getItem('userEmail') as string;

  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/UserGuideBook.pdf';
    const fileName = 'UserGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };
  const downloadFileList = [
    { fileUrl: '/setup.sh', fileName: 'setup.sh' },
    { fileUrl: '/ipp_update_script.sh', fileName: 'ipp_update_script.sh' }
  ];
  const downloadScript = () => {
    for (const file of downloadFileList) {
      downloadFile(file.fileUrl, file.fileName);
    }
  };
  // This function is used to fetch federated session from backend and save it in component state
  const fetchFederatedSession = async () => {
    setIsFederatedSessionLoading(true);
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, userEmail, role);
      setAccessKey(responseData.body.data.Credentials?.AccessKeyId ?? '');
      setSecret(responseData.body.data.Credentials?.SecretAccessKey ?? '');
      setSession(responseData.body.data.Credentials?.SessionToken ?? '');
      setRoleArn(role);
      setIsShowCred(true);
    } catch (error) {
      setError('JSON parsing error: ' + error); // Handle JSON parsing error
    }
    setIsFederatedSessionLoading(false);
  };
  // this function is used to get sign-in token from backend and send it to aws federetion endpoint for console login.
  const getCredentials = async () => {
    setOpeningCodeCommit(true);
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, userEmail, role);
      const awsLoginObj = new AwsLogin({
        accessKey: responseData.body.data.Credentials?.AccessKeyId,
        secret: responseData.body.data.Credentials?.SecretAccessKey,
        session: responseData.body.data.Credentials?.SessionToken
      });
      const consolLoginStatus = await awsLoginObj.consoleLogin();
      if (consolLoginStatus.statusCode === 201) {
        const SigninToken = consolLoginStatus.body.SigninToken;
        // construct the url with signin token for aws login
        const getLoginUrl = `${AWS_SIGNIN_URL}${SigninToken}`;
        window.open(getLoginUrl, '_blank', 'noopener,noreferrer');
      }
    } catch {
      setError('error: in aws console login' + error); // Handle console login error
    }
    setOpeningCodeCommit(false);
  };

  const copyCredentials = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await navigator.clipboard.writeText(`aws_access_key_id: ${accessKey}
  aws_secret_access_key: ${secret}
  aws_session_token: ${session}`);
    setCopiedCred(true);
    setTimeout(() => {
      setCopiedCred(false);
    }, 1000);
  };

  useEffect(() => {
    const getUserData = async (email: string) => {
      try {
        const userObj = new OrgUser({
          email: userEmail ?? ''
        });

        // API responses have been merged since actions are same
        const userData = await userObj.getUserDetails();
        setModuleList(userData.body.moduleList);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    void getUserData(userEmail ?? '');
  }, []);

  return (
    <div className="overflow-hidden relative block">
      <div className="bg-offwhite rounded-md shadow-md py-6 mt-6 relative">
        <div className="shadow-sm pb-2">
          <IPPHeading
            headerText={'Local Development Setup'}
            className={'w-fit text-black text-lg font-medium border-b-2 pt-2 pb-2 pl-6 pr-2'}
          />
          <div className="p-4">
            <Downloadfile onGuideBook={downloadUserGuide} onScript={downloadScript} />
          </div>
        </div>
        <div className="mt-6">
          <IPPHeading
            headerText={'Access Repository'}
            className={'w-fit text-black text-lg font-medium border-b-2 pb-2 pl-6 pr-2'}
          />
          <div className="p-4">
            <div className="flex gap-6">
              <Button
                onclick={fetchFederatedSession}
                className="bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:bg-black hover:text-white py-2 px-2 border border-black-500 hover:border-solid rounded"
                buttonText="Get AWS Cred."
                disabled={isFederatedSessionLoading}
              />
              <button
                className="relative bg-transparent w-48 hover:bg-blue-500 text-blue-700 font-semibold hover:scale-105 py-2 pl-2 pr-6 border border-black-500 hover:border-solid rounded"
                onClick={getCredentials}
                disabled={openingCodeCommit}
              >
                {openingCodeCommit ? 'Please Wait...' : 'View in CodeCommit'}
                <img className="absolute top-1 right-1 h-4 w-4" src={newTabIcon} />
              </button>
            </div>
            <ConditionalLoader isLoading={isFederatedSessionLoading}>
              {isShowCred && (
                <div className="pt-2">
                  <div className="p-4 mb-2 shadow-inner border border-light-gray rounded-md relative">
                    <div className="w-full flex justify-end">
                      <button
                        className="text-black hover:scale-110 disabled:hover:scale-0 shadow-inner px-4 rounded-md -mr-2 -mt-2"
                        onClick={copyCredentials}
                        disabled={copiedCred}
                      >
                        {copiedCred ? (
                          <small> Copied ✅</small>
                        ) : (
                          <small>
                            <CopyIcon className="h-3 w-3" /> Copy
                          </small>
                        )}
                      </button>
                    </div>
                    <IPPFourthLevelHeading
                      id="aceeskeyheader"
                      headerText={`aws_access_key_id: ${accessKey}`}
                      className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                    />
                    <IPPFourthLevelHeading
                      id="aceeskeyheader"
                      headerText={`aws_secret_access_key: ${secret}`}
                      className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                    />
                    <IPPParagraph
                      id="aceeskeyheader"
                      headerText={`aws_session_token: ${session}`}
                      className={'text-black text-[16px] mb-0.5 leading-[1.2] break-words'}
                    />
                  </div>
                  <div className="p-4 mb-2 shadow-inner border border-light-gray rounded-md">
                    <IPPFourthLevelHeading
                      id="aceeskeyheader"
                      headerText={`role_arn: ${roleARN}`}
                      className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                    />
                  </div>
                </div>
              )}
            </ConditionalLoader>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start mt-12">
        <ModuleGrid
          key={moduleList.key}
          data={moduleList}
          isLoading={isLoading}
          query={query}
          setQuery={setQuery}
          numValuesToShow={numValuesToShow}
          handleNumValuesChange={handleNumValuesChange}
        />
      </div>
    </div>
  );
};
export default UserPanel;
