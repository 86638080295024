import React, { useContext, useEffect, useState } from 'react';
import ModuleList from '../../components/BtAdminPanel/ModuleList';
import Module from '../../utils/Module/Module';
import UpdateOrganizationAdminModule from '../../utils/Module/UpdateModule';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { LoginContext } from '../../context/IppContext';

const Modules = () => {
  const { setTokenExpired } = useContext(LoginContext);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [dataModule, setDataModule] = useState<any>([]);
  const [isLoadingModule, setIsLoadingModule] = useState<boolean>(true);
  const [queryModule, setQueryModule] = useState<string>('');
  const [currentPageModule, setCurrentPageModule] = useState<number>(1);
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedModules, setSelectedModules] = useState<any>([]);
  const screenHeight = window.innerHeight;
  const modulesPerPage = screenHeight ? Math.ceil((screenHeight - 210) / 90) : 10;
  const sortedDataModule = [...dataModule].sort((a, b) => a.moduleName.localeCompare(b.moduleName));
  const filteredDataModule = sortedDataModule.filter((user: { moduleName: string }) =>
    user.moduleName.toLowerCase().includes(queryModule.toLowerCase())
  );
  // Calculate the index of the first and last items to display on the current page
  const indexOfLastItemModule = currentPageModule * modulesPerPage;
  const indexOfFirstItemModule = indexOfLastItemModule - modulesPerPage;
  const currentData = filteredDataModule.slice(indexOfFirstItemModule, indexOfLastItemModule);

  const resetPageAndSearchModule = (newQuery: string) => {
    setQueryModule(newQuery);
    handlePageChangeModule(1);
  };

  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setResponseMessage('Please Wait...');
  };

  const handlePageChangeModule = (pageNumber: number) => {
    setCurrentPageModule(pageNumber);
  };

  const getAdminOrganizationModule = async () => {
    setIsLoadingModule(true);
    const organizationAdminObj = new Module();
    const result = await organizationAdminObj.getAllModule(setTokenExpired);
    setDataModule(result);
    setIsLoadingModule(false);
  };

  const handleSubmit = async () => {
    setShowAlert(true);
    const userObj = new UpdateOrganizationAdminModule(selectedModules);
    const updateStatus = await userObj.updateModule(selectedModules);

    const errorMessage = 'Failed to update module';
    const successMessage = 'Modules updated successfully!!';
    const response = handleApiResponse(updateStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setSelectedModules([]);
    setResponseMessage(responseBody);
    setSubmitted(true);
    void getAdminOrganizationModule();
  };

  const handleCheckboxChange = async (moduleName: string, isSystemModule: boolean) => {
    // Check if the module is already selected
    const isModuleSelectedIndex = selectedModules.findIndex(
      (module: { moduleName: string }) => module.moduleName === moduleName
    );

    // Update the selected modules array
    if (isModuleSelectedIndex !== -1) {
      // Module is already selected, so remove it
      const updatedModules = [...selectedModules];
      updatedModules.splice(isModuleSelectedIndex, 1);
      await setSelectedModules(updatedModules);
    } else {
      // Module is not selected, so add it
      const updatedModules = [...selectedModules, { moduleName, isSystemModule }];
      await setSelectedModules(updatedModules);
    }

    setSubmitted(false);
  };

  // Close the pop-up form
  const popupCloseHandler = () => {
    setVisibility(false);
    setOpenPopupIndex(null);
  };
  // Open the popup for the specified row
  const popupOpenHandler = (index: number) => {
    setVisibility(true);
    setOpenPopupIndex(index);
  };

  useEffect(() => {
    resetPageAndSearchModule('');
    void getAdminOrganizationModule();
  }, []);

  return (
    <ModuleList
      isLoading={isLoadingModule}
      visibility={visibility}
      popupOpenHandler={popupOpenHandler}
      popupCloseHandler={popupCloseHandler}
      openPopupIndex={openPopupIndex}
      itemsPerPage={modulesPerPage}
      currentPage={currentPageModule}
      currentData={currentData}
      handlePageChange={handlePageChangeModule}
      responseMessage={responseMessage}
      showAlert={showAlert}
      popupCloseAlertHandler={popupCloseAlertHandler}
      submitted={submitted}
      selectedModules={selectedModules}
      handleSubmit={handleSubmit}
      handleCheckboxChange={handleCheckboxChange}
      resetPageAndSearch={resetPageAndSearchModule}
      filteredDataModule={filteredDataModule}
      showPagination={true}
    />
  );
};

export default Modules;
