import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserList from '../components/AdminPanel/UserList';
import ModuleList from '../components/AdminPanel/ModuleList';
import { LoginContext } from '../context/IppContext';
import { FederatedIdentity } from '../utils/AccessCredentials/FederatedIdentity';
import OrganizationUser from '../utils/OrganizationUser/OrganizationUser';
import { downloadFile } from '../utils/Helper/downloadFiles';
import Downloadfile from '../library/DownloadFiles/DownloadFile';
import AwsLogin from '../utils/AccessCredentials/AwsLogin';
import { AWS_SIGNIN_URL } from '../utils/Helper/helper';
import { IPPFourthLevelHeading, IPPHeading, IPPParagraph } from '../library/Heading/Heading';
import Button from '../library/Button/button';
import IUserList from '../utils/UserList/UserList.interface';
import ConditionalLoader from '../components/Hoc/ConditionalLoader';
import newTabIcon from '../assets/img/newTabIcon.jpg';
import CopyIcon from '@rsuite/icons/Copy';

const AdminPanel: React.FC = () => {
  const { loginToken, setTokenExpired } = useContext(LoginContext);
  const [accessKey, setAccessKey] = useState<String>('');
  const [secret, setSecret] = useState<String>('');
  const [session, setSession] = useState<String>('');
  const [roleARN, setRoleArn] = useState<String>('');
  const [error, setError] = useState<string>(''); // New error state
  const [isFederatedSessionLoading, setIsFederatedSessionLoading] = useState<boolean>(false);
  const [openingCodeCommit, setOpeningCodeCommit] = useState<boolean>(false);
  const parsedLoginToken = JSON.parse(loginToken);
  const userPoolId = parsedLoginToken.userPoolId;
  const identityPoolId = parsedLoginToken.IdentityPoolId;
  const federatedIdentity = new FederatedIdentity({
    userPoolId,
    identityPoolId
  });
  const [isShowCred, setIsShowCred] = useState<boolean>(false);
  // Storing the user's name and displaying it as a welcome page
  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState('');
  const [dataUser, setDataUser]: any = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [queryUser, setQueryUser] = useState('');
  const [copiedCred, setCopiedCred] = useState<boolean>(false);
  // Download UserGuideBook and script.
  const downloadUserGuide = () => {
    const fileUrl = '/UserGuideBook.pdf';
    const fileName = 'UserGuideBook'; // Set the desired file name
    downloadFile(fileUrl, fileName);
  };
  const downloadFileList = [
    { fileUrl: '/setup.sh', fileName: 'setup.sh' },
    { fileUrl: '/ipp_update_script.sh', fileName: 'ipp_update_script.sh' }
  ];
  const downloadScript = () => {
    for (const file of downloadFileList) {
      downloadFile(file.fileUrl, file.fileName);
    }
  };

  const userName = sessionStorage.getItem('adminFullName') ?? '';
  const orgEmailUpdated = sessionStorage.getItem('userEmail');

  const emailToUse = orgEmailUpdated ?? '';

  // This function is used to fetch federated session from backend and save it in component state
  const fetchFederatedSession = async () => {
    setIsFederatedSessionLoading(true);
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, emailToUse, role);
      setAccessKey(responseData.body.data.Credentials?.AccessKeyId ?? '');
      setSecret(responseData.body.data.Credentials?.SecretAccessKey ?? '');
      setSession(responseData.body.data.Credentials?.SessionToken ?? '');
      setRoleArn(role);
      setIsShowCred(true);
    } catch (error) {
      setError('JSON parsing error: ' + error); // Handle JSON parsing error
    }
    setIsFederatedSessionLoading(false);
    setCopiedCred(false);
  };
  // this function is used to get sign-in token from backend and send it to aws federetion endpoint for console login.
  const getCredentials = async () => {
    setOpeningCodeCommit(true);
    try {
      const idToken = parsedLoginToken.cognitoDetails.message.idToken.jwtToken;
      const role =
        parsedLoginToken.cognitoDetails.message.idToken.payload['cognito:preferred_role'];
      const responseData = await federatedIdentity.fetchAWSCredentials(idToken, emailToUse, role);
      const awsLoginObj = new AwsLogin({
        accessKey: responseData.body.data.Credentials?.AccessKeyId,
        secret: responseData.body.data.Credentials?.SecretAccessKey,
        session: responseData.body.data.Credentials?.SessionToken
      });
      const consolLoginStatus = await awsLoginObj.consoleLogin();
      if (consolLoginStatus.statusCode === 201) {
        const SigninToken = consolLoginStatus.body.SigninToken;
        // construct the url with signin token for aws login
        const getLoginUrl = `${AWS_SIGNIN_URL}${SigninToken}`;
        window.open(getLoginUrl, '_blank', 'noopener,noreferrer');
      }
    } catch {
      setError('error: in aws console login' + error); // Handle console login error
    }
    setOpeningCodeCommit(false);
  };

  const handleEditUser = (user: IUserList) => {
    const userIndex = dataUser.findIndex((u: IUserList) => u.email === user.email);
    if (userIndex !== -1) {
      const newUserList = [...dataUser];
      newUserList[userIndex] = user;
      setDataUser(newUserList);
    }
  };

  const handleDeleteUser = (email: string) => {
    const updatedDataUser = dataUser.filter((user: { email: any }) => user.email !== email);
    setDataUser(updatedDataUser);
  };

  // *********** UseEffect to handle delete user and create user *********** //
  const getAdminUsers = async () => {
    const organizationAdminObj = new OrganizationUser();
    const { users } = await organizationAdminObj.getAllAdminUser(setTokenExpired);
    setDataUser(users);
    setIsLoadingUser(false);
  };
  useEffect(() => {
    void getAdminUsers();
  }, []);

  const copyCredentials = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await navigator.clipboard.writeText(`aws_access_key_id: ${accessKey}
aws_secret_access_key: ${secret}
aws_session_token: ${session}`);
    setCopiedCred(true);
    setTimeout(() => {
      setCopiedCred(false);
    }, 1000);
  };

  // Fetching data from db and mapping it to the array. Here the promise returned is void.
  useEffect(() => {
    const getAdminUsersModules = async () => {
      const organizationAdminObj = new OrganizationUser();
      const result = await organizationAdminObj.getAllAdminUserModule(setTokenExpired);
      setData(result);
      setIsLoading(false);
    };
    void getAdminUsersModules();
  }, []);

  return (
    <div className="overflow-hidden relative block">
      <div className="bg-offwhite rounded-md shadow-md py-6 relative my-6">
        <IPPHeading
          headerText={'Local Development Setup'}
          className={'w-fit text-black text-lg font-medium border-b-2 pt-2 pb-2 pl-6 pr-2'}
        />
        <div className="max-w-full flex flex-wrap gap-4 pt-4 pl-4">
          <div className="max-w-full flex-grow-0 flex gap-4">
            <Button
              onclick={fetchFederatedSession}
              className="h-fit w-fit bg-transparent font-semibold disabled:cursor-progress hover:bg-black border rounded hover:text-white py-2 px-6"
              buttonText="Get AWS Cred."
              disabled={isFederatedSessionLoading}
            />
            <button
              className="h-fit w-fit relative bg-transparent border hover:bg-gradient-to-t from-light-gray to-gray rounded font-semibold py-2 pl-2 pr-6"
              onClick={getCredentials}
              disabled={openingCodeCommit}
            >
              {openingCodeCommit ? 'Please Wait...' : 'View in CodeCommit'}
              <img className="absolute top-1 right-1 h-4 w-4" src={newTabIcon} />
            </button>
          </div>
          <div className="flex-grow-0">
            <Downloadfile onGuideBook={downloadUserGuide} onScript={downloadScript} />
          </div>
        </div>
        <ConditionalLoader isLoading={isFederatedSessionLoading}>
          {isShowCred && (
            <div className="max-w-full pt-2 px-4">
              <div className="p-4 mb-2 shadow-inner border border-light-gray rounded-md relative overflow-hidden">
                <div className="w-full flex justify-end">
                  <button
                    className="text-black hover:scale-110 disabled:hover:scale-0 shadow-inner px-4 rounded-md -mr-2 -mt-2"
                    onClick={copyCredentials}
                    disabled={copiedCred}
                  >
                    {copiedCred ? (
                      <small> Copied ✅</small>
                    ) : (
                      <small>
                        <CopyIcon className="h-3 w-3" /> Copy
                      </small>
                    )}
                  </button>
                </div>
                <IPPFourthLevelHeading
                  id="aceeskeyheader"
                  headerText={`aws_access_key_id: ${accessKey}`}
                  className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                />
                <IPPFourthLevelHeading
                  id="aceeskeyheader"
                  headerText={`aws_secret_access_key: ${secret}`}
                  className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                />
                <IPPParagraph
                  id="aceeskeyheader"
                  headerText={`aws_session_token: ${session}`}
                  className={'text-black text-[16px] mb-0.5 leading-[1.2] break-words'}
                />
              </div>
              <div className="p-4 mb-2 shadow-inner border border-light-gray rounded-md">
                <IPPFourthLevelHeading
                  id="aceeskeyheader"
                  headerText={`role_arn: ${roleARN}`}
                  className={'text-black text-[16px] mb-0.5 leading-[1.2]'}
                />
              </div>
            </div>
          )}
        </ConditionalLoader>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:flex lg:flex flex-row justify-start lg:mt-18 overflow-x-scroll 3xl:w-2/5 3xl:pr-4 my-8">
          <UserList
            isLoading={isLoadingUser}
            query={queryUser}
            setQuery={setQueryUser}
            users={dataUser}
            email={emailToUse}
            partnerName={userName}
            onDeleteUser={handleDeleteUser}
            onUserCreated={getAdminUsers}
            onEditUser={handleEditUser}
          />
        </div>
        <div className="md:flex lg:flex w-full 3xl:w-3/5 flex-row justify-start shadow-md bg-offwhite rounded-md my-6">
          <ModuleList data={data} isLoading={isLoading} query={query} setQuery={setQuery} />
        </div>
      </div>
    </div>
  );
};
export default AdminPanel;
