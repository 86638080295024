import React, { Fragment, useEffect, useState } from 'react';
import Edit from '../../assets/img/edit.png';
import Search from '../Search';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import ReactLoading from 'react-loading';
import Button from '../../library/Button/button';
import Pagination from '../../library/Pagination/Pagination';
import InputField from '../../library/inputField/inputField';
import { IPPFourthLevelHeading, IPPHeading, IPPParagraph } from '../../library/Heading/Heading';
import ConditionalLoader from '../Hoc/ConditionalLoader';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 * @valuesToShow - Store the @numValuesToShow data.
 * @openPopupIndex - To open the pop-up containing repos based on selecting the number of partner.
 * @visibility - To make the pop-up visible.
 * @popupOpenHandler - To handle the pop-up open functionality.
 * @popupCloseHandler - To handle the pop-up close functionality.
 * @itemsPerPage - To show the number of items in one pagination tab.
 * @currentPage - The current page of data being displayed.
 * @currentData - The data to be displayed on the current page.
 * @handlePageChange - Function to handle a change in the current page.
 * @responseMessage - Message to display in response to user actions.
 * @showAlert - Boolean indicating whether an alert should be shown.
 * @popupCloseAlertHandler - Function to handle closing the alert popup.
 * @submitted - Boolean indicating whether a form has been submitted.
 * @selectedModules - Array of selected modules.
 * @handleSubmit - Function to handle form submission.
 * @handleCheckboxChange - Function to handle changes in checkbox states for modules.
 * @resetPageAndSearch - Callback function to reset the page and perform a search based on a new query.
 * @filteredDataModule - Data to be displayed after filtering based on user input.
 * @showPagination - Boolean indicating whether pagination should be displayed.
 */

interface ModuleListProps {
  isLoading: boolean;
  openPopupIndex?: number | null;
  visibility: boolean;
  popupOpenHandler: any;
  popupCloseHandler: any;
  itemsPerPage: number;
  currentPage: number;
  currentData: any;
  handlePageChange: (pageNumber: number) => void;
  responseMessage: string;
  showAlert: boolean;
  popupCloseAlertHandler: (e: boolean) => void;
  submitted: boolean;
  selectedModules: any;
  handleSubmit: () => Promise<void>;
  handleCheckboxChange: (moduleName: string, isSystemModule: boolean) => Promise<void>;
  resetPageAndSearch: (newQuery: string) => void;
  filteredDataModule: any;
  showPagination: boolean;
}

// ModuleList is a functional component which displays a table of BT admin modules data.
const ModuleList: React.FC<ModuleListProps> = ({
  isLoading,
  openPopupIndex,
  visibility,
  popupOpenHandler,
  popupCloseHandler,
  itemsPerPage,
  currentPage,
  currentData,
  handlePageChange,
  responseMessage,
  showAlert,
  popupCloseAlertHandler,
  submitted,
  selectedModules,
  handleSubmit,
  handleCheckboxChange,
  resetPageAndSearch,
  filteredDataModule,
  showPagination
}) => {
  const partnerName = currentData.map(
    (element: { orgAccessList: string | any[] }) => element.orgAccessList.length
  );

  return (
    <Fragment>
      <div className="w-full md:w-full">
        <div className="bg-white block md:w-[100%] mx-auto md:ps-0.5 md:pe-0.5">
          <div className="py-2">
            <Search
              onChange={(e: any) => resetPageAndSearch(e.target.value)}
              text="Search by module..."
              hideLabel
            />
          </div>
          <ConditionalLoader isLoading={isLoading}>
            <Fragment>
              {filteredDataModule.length > 0 ? (
                <Fragment>
                  <div className="w-full overflow-y-scroll mb-4">
                    <div className="min-w-fit border rounded-md overflow-hidden">
                      <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                        <thead className="bg-[#f8f8f8]">
                          <tr className="text-black text-left">
                            <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                              {' '}
                              Module Name{' '}
                            </th>
                            <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                              {' '}
                              No. of Partners{' '}
                            </th>
                            <th className="font-semibold text-sm uppercase px-6 py-4 text-left">
                              {' '}
                              Is Custom Module{' '}
                            </th>
                            <th className="font-semibold text-sm uppercase px-6 py-4 text-left">
                              {' '}
                              Action{' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {/* Filter the table data based on query given by user in search box */}
                          {currentData.map((element: IModuleList, index: number) => {
                            return (
                              <Fragment key={index}>
                                <tr>
                                  <td className="px-6 py-4 text-center">
                                    {' '}
                                    <p> {element.moduleName} </p>{' '}
                                  </td>
                                  <td className="px-10 py-4 text-center">
                                    {' '}
                                    <Button onclick={() => popupOpenHandler(index)}>
                                      {partnerName[index] > !element.partner
                                        ? partnerName[index]
                                        : element.partner}
                                    </Button>{' '}
                                    {openPopupIndex === index && (
                                      <CustomPopup
                                        onClose={popupCloseHandler}
                                        show={visibility}
                                        width={'w-41%'}
                                      >
                                        <div className="flex start justify-between px-4 py-4 border-b border-black">
                                          <IPPFourthLevelHeading
                                            headerText={'Partner Module'}
                                            className={'mb-0 leading-normal text-2xl text-black'}
                                          />
                                        </div>
                                        <div className="position-relative grow shrink basis-auto px-4 py-4 block max-h-[30rem] overflow-y-auto">
                                          <table className="w-100%  border-collapse ">
                                            <thead className="table-header-group align-middle bg-[#f8f8f8] ">
                                              <tr className="text-black text-left table-row align-inherit">
                                                <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                                  Partner Name
                                                </th>
                                                <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                                  Access
                                                </th>
                                                <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {element.orgAccessList.map(
                                                (info: any, index: number) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td className="px-6 py-4 text-center">
                                                        {info.orgName}
                                                      </td>
                                                      <td className="px-6 py-4 text-center ">
                                                        {info.accessType}
                                                      </td>
                                                      <td className="px-10 py-4 text-center">
                                                        <ImageTag
                                                          src={Edit}
                                                          width={'20px'}
                                                          alt={''}
                                                          name={'Edit'}
                                                        />
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </CustomPopup>
                                    )}
                                  </td>

                                  <td className="pl-[2.75rem] pr-[1.75rem] py-4 object-center cursor-not-allowed">
                                    <div className="flex">
                                      {' '}
                                      <label className="relative inline-flex items-center cursor-pointer pl-[2rem]">
                                        <InputField
                                          type="checkbox"
                                          value={element.moduleName}
                                          className="sr-only peer"
                                          checked={
                                            element.isSystemModule
                                              ? !selectedModules.some(
                                                  (module: { moduleName: string }) =>
                                                    module.moduleName === element.moduleName
                                                )
                                              : selectedModules.some(
                                                  (module: { moduleName: string }) =>
                                                    module.moduleName === element.moduleName
                                                )
                                          }
                                          onChange={async (
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) =>
                                            await handleCheckboxChange(
                                              element.moduleName,
                                              e.target.checked
                                            )
                                          }
                                          id={'moduleName'}
                                        />
                                        <div className="w-11 h-6 bg-gray peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all after:ml-[2rem] dark:border-gray-600 peer-checked:bg-black" />
                                      </label>
                                    </div>
                                  </td>

                                  <td className="pl-[2.75rem] pr-[1.75rem] py-4 object-center cursor-not-allowed">
                                    <div className="flex">
                                      {' '}
                                      <ImageTag src={Edit} width={'20px'} alt={''} name={'Edit'} />
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {filteredDataModule.length > 0 && (
                    <div className="flex justify-end">
                      <Button
                        className={
                          submitted || selectedModules.length === 0
                            ? 'inline-block text-white text-lg cursor-not-allowed bg-black rounded-md mr-2 md:mr-0 px-8 py-2 bg-trans disabled:opacity-20'
                            : 'inline-block text-white text-lg cursor-pointer bg-black rounded-md mr-2 md:mr-0 px-8 py-2'
                        }
                        buttonText="Update"
                        onclick={handleSubmit}
                        disabled={submitted || selectedModules.length === 0}
                      />
                    </div>
                  )}
                  {showPagination && filteredDataModule.length > itemsPerPage && (
                    <div className="mt-4">
                      <Pagination
                        className={'pagination-bar flex justify-center items-center m-auto'}
                        currentPage={currentPage}
                        totalCount={filteredDataModule.length}
                        pageSize={itemsPerPage}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </Fragment>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
                </div>
              )}
            </Fragment>
          </ConditionalLoader>
        </div>
      </div>
      {showAlert && (
        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
          <div className="max-h-30% ">
            <IPPParagraph
              headerText={`${responseMessage}`}
              className={'mr-0 mb-0.5 ml-0 text-[17px]'}
            />
          </div>
        </CustomPopup>
      )}
    </Fragment>
  );
};

export default ModuleList;
