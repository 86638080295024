import React, { useContext, useEffect, useId, useState } from 'react';
import { EditAdminUserForm } from '../components/BtAdminPanel/EditAdminUsers';
import { AdminUserForm } from '../components/BtAdminPanel/AdminUsers';
import { DeleteOrg } from '../components/BtAdminPanel/DeleteOrganisation';
import ResetMfa from '../components/BtAdminPanel/ResetMfa';
import AssignedModuleForm from '../components/BtAdminPanel/AssignedModules';
import { IPreset } from '../utils/ModulePresets/preset.interface';
import { EditUserForm } from '../components/AdminPanel/EditUser';
import IModuleList from '../utils/ModuleList/ModuleList.interface';
import IUserList from '../utils/UserList/UserList.interface';
import OrgUpdate from '../components/BtAdminPanel/OrgUpdate';
import AllOrganizationAdmin from '../utils/OrganizationUser/AllOrganisationUser';
import Button from '../library/Button/button';
import ImageTag from '../library/ImageTag/ImageTag';
import domain from '../assets/img/domain.png';
import user from '../assets/img/user.png';
import { OrgUserForm } from '../components/BtAdminPanel/OrgUsers';
import { LoginContext } from '../context/IppContext';

interface Props {
  partner: IUserList;
  presets: IPreset[];
  systemModuleName: IModuleList[];
  baseModules: IModuleList[];
  onChangePartner: (partner: IUserList) => void;
}

const PartnerAdminRow = ({
  partner,
  presets,
  systemModuleName,
  baseModules,
  onChangePartner
}: Props) => {
  const { setTokenExpired } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showOrgUpdate, setShowOrgUpdate] = useState<boolean>(false);
  const [showUsers, setshowUsers] = useState<boolean>(false);
  const [isDomainShowing, setIsDomainShowing] = useState<boolean>(false);
  const [isUsernShowing, setIsUserShowing] = useState<boolean>(false);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [usersData, setUsersData] = useState<IUserList[]>([]);
  const uniqueId = useId();

  const handleDeleteUser = (email: string) => {
    const index = usersData.findIndex((item) => item.email === email);
    if (index !== -1) {
      setUsersData([...usersData.slice(0, index), ...usersData.slice(index + 1)]);
    }
    const status = Number(partner.status) - 1;
    onChangePartner({
      ...partner,
      status: status.toString()
    });
  };

  const handleUserDetailChangeOnUI = (userDetails: IUserList) => {
    setUsersData((prvs) => {
      const index = prvs.findIndex((item) => item.email === userDetails.email);
      const updatedUsers = [...prvs];
      if (index !== -1) {
        updatedUsers[index] = {
          ...updatedUsers[index],
          role: userDetails.role,
          name: userDetails.name,
          phone: userDetails.phone
        };
      }
      return updatedUsers;
    });
  };

  const handleUserAdd = () => {
    const status = Number(partner.status) + 1;
    onChangePartner({
      ...partner,
      status: status.toString()
    });
  };

  useEffect(() => {
    if ((showOrgUpdate || showUsers) && partner.email) {
      const getAdminUsers = async () => {
        setIsLoading(true);
        const organizationAdminObj = new AllOrganizationAdmin({ email: partner.email ?? '' });
        const result = (await organizationAdminObj.getAllAdminUser(setTokenExpired)) as IUserList[];
        const partnerEmailList: string[] = result.map((user) => {
          return user?.email ?? '';
        });
        partnerEmailList.push(partner.email ?? '');
        setEmailList(partnerEmailList);
        setUsersData(result);
        setIsLoading(false);
      };
      void getAdminUsers();
    }
  }, [showOrgUpdate, showUsers]);

  return (
    <tr className="even:bg-[#f8f8f8]">
      <td className="text-left text-base py-2 pl-6">{partner?.companyName}</td>
      <td className="text-left text-base py-2 pl-6">{partner?.email}</td>
      <td className="text-center text-base py-2">{partner?.status}</td>
      <td className="text-center text-base py-2">{partner?.module}</td>
      <td className="min-w-[150px] text-center text-base py-2">
        {partner.buttonStatus === null ? (
          <>
            <EditUserForm
              orgPartnerCompanyEmail={''}
              userName={partner.name}
              orgJobTitle={partner.role}
              orgPartnerCompanyName={partner.companyName}
              userEmail={partner.email}
              disabled={false}
              userPhone={partner.phone}
              onEditUser={handleUserDetailChangeOnUI}
            />
          </>
        ) : (
          <div className="w-fit mx-auto">
            <div className="flex w-fit pb-1 min-w-fit [&>*]:flex-shrink-0 gap-[2px]">
              <EditAdminUserForm
                userTitle={partner.title}
                userFname={partner.fullname}
                userLname={partner.lname}
                orgJobTitle={partner.role}
                orgPhone={partner.phone}
                orgEmail={partner.email}
                orgPartnerCompanyName={partner.companyName}
                orgDescription={partner.description}
                orgCompanyHaedquaterAddress={partner.companyHaedquaterAddress}
              />
              <DeleteOrg organisationName={partner.companyName} organisationEmail={partner.email} />
              <AdminUserForm
                organisationName={partner.companyName ?? ''}
                organisationEmail={partner.email ?? ''}
                domains={partner.domains}
                onUserAdd={handleUserAdd}
              />
              <ResetMfa userEmail={partner.email} disabled={false} />
              <Button
                id={`domail-update-${uniqueId}`}
                aria-label="Edit Organisation"
                className="bg-transparent"
                onclick={() => {
                  setShowOrgUpdate(true);
                }}
                onMouseEnter={() => setIsDomainShowing(true)}
                onMouseLeave={() => setIsDomainShowing(false)}
              >
                <ImageTag src={domain} width={'19px'} alt={''} name={'Domain'} />
                {isDomainShowing && (
                  <div className="group flex relative">
                    <span
                      className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 -left-1/2
              -translate-x-1/2 translate-y-full m-4 mx-auto"
                    >
                      Organization Domains
                    </span>
                  </div>
                )}
              </Button>
              <Button
                id={`user-update-${uniqueId}`}
                aria-label="Edit Organisation"
                className="bg-transparent"
                onclick={() => {
                  setshowUsers(true);
                }}
                onMouseEnter={() => setIsUserShowing(true)}
                onMouseLeave={() => setIsUserShowing(false)}
              >
                <ImageTag src={user} width={'19px'} alt={''} name={'User'} />
                {isUsernShowing && (
                  <div className="group flex relative">
                    <span
                      className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 -left-3/4
              -translate-x-1/2 translate-y-full m-4 mx-auto"
                    >
                      Organization Users
                    </span>
                  </div>
                )}
              </Button>
              {showOrgUpdate && (
                <OrgUpdate
                  isLoading={isLoading}
                  email={partner.email ?? ''}
                  name={partner.companyName ?? ''}
                  show={showOrgUpdate}
                  setShow={setShowOrgUpdate}
                  userTitle={partner.title}
                  userFname={partner.fullname}
                  userLname={partner.lname}
                  orgPartnerCompanyName={partner.companyName}
                  orgDescription={partner.description}
                  orgCompanyHaedquaterAddress={partner.companyHaedquaterAddress}
                  domains={partner.domains}
                  emailList={emailList}
                />
              )}
              {showUsers && (
                <OrgUserForm
                  usersData={usersData}
                  query={''}
                  disabled={false}
                  show={showUsers}
                  setShow={setshowUsers}
                  isLoading={isLoading}
                  onDeleteUser={handleDeleteUser}
                  onEditUser={handleUserDetailChangeOnUI}
                />
              )}
            </div>
            <AssignedModuleForm
              organisationName={partner.companyName ?? ''}
              presets={presets}
              systemModules={systemModuleName}
              baseModules={baseModules}
              partner={partner}
              onChangePartner={onChangePartner}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default PartnerAdminRow;
