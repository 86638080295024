import React, { Fragment, useCallback } from 'react';
import IUserList from '../../utils/UserList/UserList.interface';
import { IPPHeading } from '../../library/Heading/Heading';
import { ModuleAccessLevel } from '../../utils/Helper/enums';
import ConditionalLoader from '../Hoc/ConditionalLoader';
import ModuleRow from './ModuleRow';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 **/
interface ModuleListProps {
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}

// ModuleList is a functional component which displays a table of repositories that organisation has access to.
const ModuleList: React.FC<ModuleListProps> = ({ data, isLoading, query, setQuery }) => {
  const handleAccessSelect = (e: React.MouseEvent<HTMLButtonElement>, accessType: string) => {
    e.preventDefault();
    setQuery(accessType);
  };

  const moduleList = data
    .reduce((previousValue, currentValue) => {
      if (currentValue.moduleList) {
        previousValue = [...previousValue, ...currentValue.moduleList];
      }
      return previousValue;
    }, [])
    .filter((info: any) => info.accessType.toLowerCase().includes(query.toLowerCase()))
    .sort((a: { moduleName: string }, b: { moduleName: any }) =>
      a.moduleName.localeCompare(b.moduleName)
    );

  return (
    <div className="w-full min-w-fit pt-8">
      <IPPHeading
        headerText={'Indigo Modules'}
        className={'w-fit text-black text-xl font-medium border-b-2 pb-2 pl-6 pr-2'}
      />
      <div className="flex justify-end gap-4 px-2 sm:px-8">
        <div
          className={`flex justify-center items-center gap-2 p-2 ${
            query === '' ? 'border-b-2 border-gray' : ''
          }`}
        >
          {' '}
          <div className="w-1 h-1 rounded-full p-1 bg-gray"></div>
          <button
            onClick={(e) => {
              handleAccessSelect(e, '');
            }}
          >
            All
          </button>
        </div>
        <div
          className={`flex justify-center items-center gap-2 p-2 ${
            query === ModuleAccessLevel.FULLACCESS ? 'border-b-2 border-green' : ''
          }`}
        >
          {' '}
          <div className="w-1 h-1 rounded-full p-1 bg-green"></div>
          <button
            onClick={(e) => {
              handleAccessSelect(e, ModuleAccessLevel.FULLACCESS);
            }}
          >
            {ModuleAccessLevel.FULLACCESS}
          </button>
        </div>
        <div
          className={`flex justify-center items-center gap-2 p-2 ${
            query === ModuleAccessLevel.PARTIALACCESS ? 'border-b-2 border-yellow' : ''
          }`}
        >
          {' '}
          <div className="w-1 h-1 rounded-full p-1 bg-yellow"></div>
          <button
            onClick={(e) => {
              handleAccessSelect(e, ModuleAccessLevel.PARTIALACCESS);
            }}
          >
            {ModuleAccessLevel.PARTIALACCESS}
          </button>
        </div>
      </div>
      <br />
      <ConditionalLoader isLoading={isLoading} width={500}>
        <div className="w-full px-2 sm:px-8 pt-0 pb-12">
          {moduleList.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
              {moduleList.map((info: any) => (
                <ModuleRow
                  key={info.moduleName}
                  name={info.moduleName!}
                  accessType={info.accessType}
                  disabled={true}
                />
              ))}
            </div>
          ) : (
            <div className="px-6 py-4 text-center font-bold">
              <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
            </div>
          )}
        </div>
      </ConditionalLoader>
    </div>
  );
};

export default ModuleList;
