import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import ConditionalLoader from '../Hoc/ConditionalLoader';
import ModuleinfoCard from './ModuleinfoCard';
import { IPPHeading } from '../../library/Heading/Heading';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 **/
interface ModuleGridProps {
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  numValuesToShow: string;
  handleNumValuesChange: any;
}

// ModuleGrid is a functional component which displays a grid of repositories that organisation has access to.
const ModuleGrid: React.FC<ModuleGridProps> = ({ data, isLoading }) => {
  return (
    <div className="w-full">
      <IPPHeading
        headerText={'Indigo Modules'}
        className={'w-fit text-black text-xl font-medium border-b-2 pb-2 pl-6 pr-2'}
      />
      <ConditionalLoader isLoading={isLoading} className="flex justify-center">
        <div className="w-full overflow-y-auto mt-4 px-4 pb-8">
          {data.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-6">
              {data
                .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                  a.moduleName.localeCompare(b.moduleName)
                )
                .map((item: any, index: number) => (
                  <ModuleinfoCard
                    key={item.moduleName}
                    name={item.moduleName}
                    accessType={item.accessType}
                    command={`git clone codecommit://<profile>@${item.moduleName} ${item.moduleName}`}
                  />
                ))}
            </div>
          ) : (
            <div
              className="px-6 py-4 text-center"
              style={{ paddingTop: '5rem', fontWeight: 'bold' }}
            >
              <h1 className="text-bold">No data found ☹️ !!</h1>
            </div>
          )}
        </div>
      </ConditionalLoader>
    </div>
  );
};

export default ModuleGrid;
