import React, { useState } from 'react';
import CopyIcon from '@rsuite/icons/Copy';

interface Props {
  name: string;
  accessType: string;
  command: string;
}

const ModuleinfoCard = ({ name, accessType, command }: Props) => {
  const [copiedCred, setCopiedCred] = useState<boolean>(false);

  const copyCredentials = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await navigator.clipboard.writeText(`${command}`);
    setCopiedCred(true);
    setTimeout(() => {
      setCopiedCred(false);
    }, 1000);
  };
  return (
    <div className="flex justify-between rounded-lg bg-white shadow-lg border border-gray dark:bg-neutral-700">
      <div className="p-6 pb-4">
        <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
          {name}
        </h5>
        <p className="text-base text-neutral-600 dark:text-neutral-200">
          <span className="font-semibold">Access permission:</span> {accessType}
        </p>
        <div className="text-base text-neutral-600 dark:text-neutral-200">
          <span className="font-semibold">Clone command:</span>
          <div className="px-3 py-2 border border-gray rounded-md mt-1">
            <div className="w-full flex justify-end mb-1">
              <button
                className="text-black hover:scale-110 disabled:hover:scale-0 shadow-inner shadow-gray px-4 rounded-md -mr-2 -mt-1"
                onClick={copyCredentials}
                disabled={copiedCred}
              >
                {copiedCred ? (
                  <small> Copied ✅</small>
                ) : (
                  <small>
                    <CopyIcon className="h-3 w-3" /> Copy
                  </small>
                )}
              </button>
            </div>
            <p className="text-wrap break-words">{command}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModuleinfoCard;
