import React, { useEffect, useMemo } from 'react';
import PanelLayout from './PanelLayout';
import LeftNavPanel from '../components/LeftNavPanel';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUserAndAdminInitials } from '../utils/GetInitials/GetInitials';
import { InitialFlag } from '../utils/Helper/enums';
import Button from '../library/Button/button';
import AdminTopBar from '../components/TopBar/AdminTopBar';
import { UserDetailProvider } from '../context/UserDetailContext';
import withLogout from '../components/AutoLogOut/withLogout';
import { btAdminRoutes } from '../routes/Routes';
import UserGreeting from '../components/UserGreeting';
import { requestNotificationPermission } from '../firebase';
import { Btadmin } from '../utils/btAdmin';

const BtAdminPanelLayout = () => {
  const userEmail = sessionStorage.getItem('userEmail') as string;
  const { calculatedInitials, getFullName, getFirstName } = useUserAndAdminInitials(
    userEmail,
    InitialFlag.BtAdmin
  );
  const navigate = useNavigate();

  const tabs = useMemo(
    () => [
      {
        label: 'Home',
        path: btAdminRoutes.dashboard
      },
      {
        label: 'Partner Admins',
        path: btAdminRoutes.partnerAdmin
      },
      {
        label: 'Modules',
        path: btAdminRoutes.modules
      },
      {
        label: 'Codecommit Analytics',
        path: btAdminRoutes.analytics
      },
      {
        label: 'Preset Configuration',
        path: btAdminRoutes.presets
      },
      {
        label: 'IPP Audit',
        path: btAdminRoutes.audit
      }
    ],
    []
  );
  const links = useMemo(
    () => [
      {
        label: 'Manage Licenses/Agreements',
        path: ''
      },
      {
        label: 'Documentation',
        path: ''
      }
    ],
    []
  );

  const onClick = () => {
    navigate('/register?btadmin=true');
  };

  useEffect(() => {
    const handleSubscribe = async () => {
      const token = await requestNotificationPermission();
      if (token) {
        const BtAdmin = new Btadmin();
        const response = await BtAdmin.updateNotificationToken(userEmail, token);
      }
    };
    void handleSubscribe();
  }, []);

  return (
    <UserDetailProvider
      value={{
        fullName: getFullName,
        firstName: getFirstName,
        calculatedInitials
      }}
    >
      <PanelLayout header={<AdminTopBar btAdmin={true} />} footer={<></>}>
        <LeftNavPanel tabs={tabs} links={links} />
        <div className="flex-grow min-w-[350px] overflow-hidden overflow-x-auto transition-all ease-in-out overflow-y-auto px-0 pl-2 sm:px-4 lg:px-4 pb-20">
          <div className="flex justify-between items-end pt-14 md:pt-4 pb-2">
            <UserGreeting name={getFirstName} isBtAdmin={true} />
            <Button
              className="text-[#fff] text-base cursor-pointer bg-black rounded-lg mb-0 mr-2 md:mr-0 py-2 px-4"
              buttonText="Create Organisation"
              onclick={onClick} // The onSubmit function is called when the button is clicked.
            />
          </div>
          <hr />
          <div className="w-full h-fit pb-12">
            <Outlet />
          </div>
        </div>
      </PanelLayout>
    </UserDetailProvider>
  );
};

export default withLogout(BtAdminPanelLayout);
