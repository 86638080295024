import React, { useContext, useEffect, useState } from 'react';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import { IPreset } from '../../utils/ModulePresets/preset.interface';
import Preset from '../../utils/ModulePresets/Preset';
import Module from '../../utils/Module/Module';
import IUserList from '../../utils/UserList/UserList.interface';
import PartnerAdminRow from '../PartnerAdminRow';
import Pagination from '../../library/Pagination/Pagination';
import Search from '../../components/Search';
import ConditionalLoader from '../../components/Hoc/ConditionalLoader';
import { LoginContext } from '../../context/IppContext';

const PartnerAdmin = () => {
  const { setTokenExpired } = useContext(LoginContext);
  const [partnerList, setPartnerList]: any = useState<Array<IUserList[] | null[]>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');
  const [presets, setPresets] = useState<IPreset[] | []>([]);
  const [systemModuleName, setSystemModuleName] = useState<IModuleList[]>([]);
  const [baseModules, setBaseModules] = useState<IModuleList[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const screenHeight = window.innerHeight;
  // based on screenHeight display items
  const itemsPerPage = screenHeight ? Math.ceil((screenHeight - 210) / 90) : 10;
  const sortedData = [...partnerList].sort((a, b) => a.companyName.localeCompare(b.companyName));
  const filteredPartner = sortedData.filter((user: { companyName: string }) =>
    user.companyName.toLowerCase().includes(query.toLowerCase())
  );
  const showPagination = !query;
  // Calculate the index of the first and last items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDataPartnerAdmin = filteredPartner.slice(indexOfFirstItem, indexOfLastItem);

  const resetPageAndSearch = (newQuery: string) => {
    setQuery(newQuery);
    handlePageChange(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  // Handle Partner edit on UI
  const handlePartnerChange = (partner: IUserList) => {
    setPartnerList((prvs: IUserList[]) => {
      const index = prvs.findIndex((item) => item.email === partner.email);
      const newPartnerList = [...prvs];
      newPartnerList[index] = partner;
      return newPartnerList;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // Get Partner Organizations
      const organizationAdminObj = new OrganizationAdmin();
      const partnerList = await organizationAdminObj.getAllOrganization();
      setPartnerList(partnerList);

      // Get Presets
      const presetInst = new Preset();
      const presetList = await presetInst.getAllPreset();
      setPresets(presetList ?? []);

      // Get Module list
      const module = new Module();
      const moduleList = await module.getAllModule(setTokenExpired);
      // Filter system modules based on the isSystemModule property
      const systemModules = moduleList.filter((module) => module?.isSystemModule) as IModuleList[];
      // Filter modules where isSystemModule is false
      const nonSystemModules = moduleList.filter(
        (module) => !module?.isSystemModule
      ) as IModuleList[];
      setSystemModuleName(systemModules);
      setBaseModules(nonSystemModules);
      setIsLoading(false);
    };

    void fetchData();
  }, []);

  return (
    <ConditionalLoader isLoading={isLoading} className="flex justify-center">
      <div className="w-full md:w-full">
        <div className="bg-white block md:w-[100%] mx-auto md:ps-0.5 md:pe-0.5">
          <div className="py-2">
            <Search
              onChange={(e: any) => resetPageAndSearch(e.target.value)}
              text="Search by partner..."
              hideLabel
            />
          </div>
          <div>
            {filteredPartner.length > 0 ? (
              <div className="max-w-full overflow-hidden">
                <div className="max-w-full overflow-scroll min-h-[500px]">
                  <div className="min-w-fit border rounded-md overflow-hidden">
                    <table className="min-w-fit w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                      <thead className="bg-[#f8f8f8] pr-6 lg:pr-auto">
                        <tr className="text-black text-center">
                          <th className="font-semibold text-sm uppercase px-6 py-4 text-left">
                            {' '}
                            Partner Name{' '}
                          </th>
                          <th className="font-semibold text-sm uppercase px-6 py-4 text-left">
                            {' '}
                            Admin User Name{' '}
                          </th>

                          <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                            {' '}
                            No. of Users{' '}
                          </th>
                          <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                            {' '}
                            Assigned Modules{' '}
                          </th>
                          <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                            {' '}
                            Actions{' '}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {currentDataPartnerAdmin.map((dataSet: IUserList, index: number) => (
                          <PartnerAdminRow
                            key={dataSet.key}
                            partner={dataSet}
                            presets={presets}
                            systemModuleName={systemModuleName}
                            baseModules={baseModules}
                            onChangePartner={handlePartnerChange}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {showPagination && filteredPartner.length > itemsPerPage && (
                  <Pagination
                    className={'pagination-bar mt-4 flex justify-center items-center m-auto'}
                    currentPage={currentPage}
                    totalCount={filteredPartner.length}
                    pageSize={itemsPerPage}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            ) : (
              <div
                className="px-6 py-4 text-center"
                style={{ paddingTop: '5rem', fontWeight: 'bold' }}
              >
                <h1 className="text-bold">No data found ☹️ !!</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </ConditionalLoader>
  );
};

export default PartnerAdmin;
